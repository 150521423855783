var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('heade'),_c('div',{staticClass:"com",style:({
      backgroundImage: 'url(' + require('@/assets/' + _vm.backgroud) + ')',
    })},[_c('div',{staticClass:"com_box"},[_c('div',{staticClass:"con_top"},[_c('div',{staticClass:"con_top_left"},[_c('div',{staticClass:"com-left-top"},[_vm._v(" "+_vm._s(_vm.topicGuide.practice_course_parent_division_name)+" ")]),_c('div',{staticClass:"con_left_name"},[_c('div',{staticClass:"nice-scroll"},_vm._l((_vm.topicGuide.topic_guides),function(item,index){return _c('div',{key:index},_vm._l((item.division),function(items,indexs){return _c('div',{key:indexs,attrs:{"id":_vm.topicGuide.id,"division_id":items.division_content_id},on:{"click":function($event){return _vm.replayDivisionCourse(
                      items.division_content_id,
                      item.division_id,
                      items.charge_mode
                    )}}},[_c('p',{class:_vm.isActive == items.division_content_id
                        ? 'division-active'
                        : 'division'},[_vm._v(" "+_vm._s(items.name)+" ")])])}),0)}),0)])]),_c('div',{staticClass:"con_right"},[_c('div',{staticClass:"prism-player-box"},[(_vm.options.vid != '')?_c('vue-aliplayer-v2',{ref:"VueAliplayerV2",attrs:{"options":_vm.options}}):_vm._e()],1)])]),_c('div',{staticClass:"com-btt"},[_c('div',_vm._l((_vm.practices),function(item,index){return _c('router-link',{key:index,class:item.id == _vm.topicGuide.practice_course_id
                ? 'com-bbt-item  active'
                : 'com-bbt-item ',attrs:{"to":{
              path: _vm.path,
              query: { id: item.id },
            }}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)])])]),_c('foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }